/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  LayoutProps,
  PageStaticData,
  // GenericProps
  NextRouteOptions,
} from '../../../additional'
import dynamic from 'next/dynamic'
import { useRouter as useNextRouter } from 'next/router'
import React from 'react'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'
// import React, { PropsWithChildren } from 'react'
// import { renderToString } from 'react-dom/server'
// import { Context as ResponsiveContext } from 'react-responsive'
import { observer } from 'mobx-react-lite'
import { getStores } from 'flex-gateway-ssr/stores'
// import { isServer } from 'flex-gateway-ssr/utils'
import { useHasMounted } from 'flex-gateway-ssr/utils'
// import { Desktop, TabletAndBelow } from 'flexApp/FlexResponsive'

import FlexLink from 'flex-gateway-ssr/link'

// import Navigation from '../navigation'
const Navigation = dynamic(() => import('flex-gateway-ssr/components/navigation'))
const OptionsPanelCSSMode = dynamic(() => import('flex-gateway-ssr/components/options-panel/css-mode'))
const OptionsPanelDebug = dynamic(() => import('flex-gateway-ssr/components/options-panel/debug'))
const ChangeLanguage= dynamic(() => import('flex-gateway-ssr/components/change-language'))
// import Logo from '../logo'
const Logo = dynamic(() => import('flex-gateway-ssr/components/logo'))

// CSS
// import styles from '../../styles/style-jsx/layout.css'
// if(process.env.FLEX_GATEWAY_CSS === 'default') styles = null

import { FlexComponents } from 'flex-gateway-ssr/flex-components'
const { Button, ButtonMarkup, Breadcrumb, BreadcrumbItem, Title, styles } = FlexComponents

import {
  FlexGlobalThis
} from 'flexiness'

declare let globalThis: FlexGlobalThis

const stores = getStores()

const Layout = observer(({ props, children }: LayoutProps) => {
  
  const [navMobileOpen, setNavMobileOpen] = React.useState(false)
  const nextRouter = useNextRouter()
  const hasMounted = useHasMounted()
  let currentMicroId = 'loading'

  const handleClickNavToggleOpen = () => {
    setNavMobileOpen(!navMobileOpen)
  }

  React.useEffect(() => {
    const handleRouteHasChanged = (url: string, { shallow }: NextRouteOptions) => {
      setNavMobileOpen(false)
    }
    nextRouter.events.on('routeChangeComplete', handleRouteHasChanged)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      nextRouter.events.off('routeChangeComplete', handleRouteHasChanged)
    }
  }, [nextRouter])

  if (!hasMounted) {
    return null
  } else {
    currentMicroId = globalThis?.Flexiness?.domainApp?.MFStore?.currentMicroId || 'loading'
  }

  // const { navigationState, status } = globalThis?.Flexiness?.domainApp?.UIStore
  const { appContext, navigationState, status } = stores.UIStore

  const router = useNextRouter()

  const showLogo = () => {
    return (
      <div className='logoHolder'>
        <Logo />
      </div>
    )
  }

  const loadingLayout = () => {
    switch (true) {
      case status === 'loading' && navigationState !== 'fullpage':
        return showLogo()
      case nextRouter.pathname === '/404' || nextRouter.pathname === '/500':
        return showLogo()
      default:
        return null
    }
  }

  const navigationLayout = () => {
    switch (true) {
      case navigationState === 'fullpage':
        return (
          <>
            <Navigation {...props as PageStaticData} />
          </>
        )
      case navigationState === 'aside':
        return (
          <div className={classNames(
              'grid grid-cols-1',
              // 'gap-2',
              'grid-rows-[auto]',
              'mf:flex mf:flex-col',
              'flex-gateway-nav-menu',
              `${navMobileOpen
                ? 'open'
                : 'closed'
              }`
            )}>

            <div className={classNames(
              'w-full',
              'order-1',
              'flex',
              !isMobile && 'mf:hidden', // Can be visible when isTablet() landscape
              'flex-gateway-nav-menu-item',
              'flex-gateway-nav-menu-main-mob'
            )}>

              <div className={classNames(
                'w-full',
                'grow-1',
                'flex items-center justify-start',
                'logoNavMob'
              )}>
                {/* {showLogo()} */}
                <div className='logoBadge' />
                {/* <div className='logoHolderMatte1' /> */}
                {/* <div className='logoHolderMatte2' /> */}
              </div>

              <div className={classNames(
                'grow-0',
                'flex items-center justify-end',
                'flex-gateway-nav-collapse'
              )}>
              {navMobileOpen
                ? (<button onClick={handleClickNavToggleOpen}
                    className={classNames(
                      'flex-gateway-nav-toggler-close',
                      `${appContext.theme.palette.mode === 'light'
                        ? 'stroke-grey'
                        : 'stroke-grey-invert'
                      }`
                    )}>
                    <span />
                    <span />
                  </button>)
                : (<button onClick={handleClickNavToggleOpen}>
                      <span className={classNames(
                        'navbar-toggler-icon',
                        `${appContext.theme.palette.mode === 'light'
                          ? 'stroke-grey'
                          : 'stroke-grey-invert'
                        }`
                      )} />
                    </button>)
              }
              </div>

            </div>

            <div className={classNames('w-full order-3 mf:order-1',
              'hidden mf:flex',
              'items-center justify-stretch',
              'flex-gateway-nav-menu-item',
            )}>
              <OptionsPanelCSSMode />
            </div>

            <div className={classNames('w-full order-4 mf:order-1',
              'flex items-center justify-stretch',
              'flex-gateway-nav-menu-item'
            )}>
              <OptionsPanelDebug />
            </div>

            <div className={classNames('w-full order-2',
              'flex items-center justify-stretch',
              'flex-gateway-nav-menu-item'
            )}>
              {isMobile
                ? navMobileOpen && (
                  <Navigation {...props as PageStaticData} />
                ) : (
                  <Navigation {...props as PageStaticData} />
                )
              }
            </div>

            <div className={classNames('w-full order-5',
              'flex items-center justify-stretch',
              'flex-gateway-nav-menu-item'
            )}>
              <ChangeLanguage />
            </div>

          </div>
        )
      case navigationState === 'top':
        return (
          <>
            <div className='logo'>
              <Navigation {...props as PageStaticData} />
            </div>
            <div className='menus'>
              <ChangeLanguage />
            </div>
            <div className='toggler'>
              <FlexLink href='/home'>{`\u2BBE`}</FlexLink>
            </div>
          </>
        )
      default:
        return null
    }
  }

  return (
    <div className={classNames(
      'flex-gateway-layout',
      navigationState,
      currentMicroId,
      appContext.overlayMode && 'flex-overlay',
      isMobile && 'is-flex-mobile'
    )}>
      <div className='navigation'>
        { navigationLayout() }
      </div>
      {(router.isReady && router.query.id) &&
      <div className='mt-20 w-full bg-black flex flex-col items-center'>
        <div className='mx-auto pt-5 pb-5 w-12/12 lg:w-8/12 text-white'>
          <div>
            <Breadcrumb>
              <BreadcrumbItem href='/carjager' className='carjager_highLightWhite'>Home</BreadcrumbItem>
              <BreadcrumbItem className='carjager_highLightWhite'>Nos Annonces</BreadcrumbItem>
              <BreadcrumbItem active className='carjager_highLightWhite'>{'GT & Supercars'}</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
      </div>
      }
      <div className={classNames(
        'component',
        currentMicroId,
        navigationState === 'top' && 'carjager_Page'
      )}>
        { loadingLayout() }
        { children }
      </div>
    </div>
  )
})

export default Layout
