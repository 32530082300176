/* eslint-disable @typescript-eslint/no-unused-vars */

// import log from 'loglevel'
// import { loadComponent, getFlexCSPNonce, fetchFlexCSPNonce } from '@flexiness/domain-utils'
import { isServer } from 'flex-gateway-ssr/utils'

import type {
  FlexGlobalThis
} from 'flexiness'

declare let globalThis: FlexGlobalThis
let FlexComponents: typeof import('flex_design_system_react_ts_modfed/Styled')

export async function getFlexComponents(): Promise<typeof FlexComponents> {
  return import('flex_design_system_react_ts_modfed/Styled')
    .then((factory) => {
      const FlexComponents = factory
      globalThis.Flexiness = {
        // @ts-expect-error
        ...globalThis?.Flexiness,
        domainApp: { ...globalThis?.Flexiness?.domainApp, FlexComponents }
      }
      return FlexComponents as typeof FlexComponents
    }).catch(err => {
      throw new Error(`Module not found : ${err}`)
    })
}

if (isServer) {
  FlexComponents = await getFlexComponents()
  // log.info('isServer FlexComponents : ', FlexComponents)
}

if (!isServer) {
  // @ts-expect-error
  FlexComponents = globalThis?.Flexiness?.domainApp?.FlexComponents
  if (!FlexComponents) {
    const { loadComponent, fetchFlexCSPNonce } = await import(
      /* webpackMode: "lazy-once" */
      /* eager: true */
      /* webpackPreload: true */
      '@flexiness/domain-utils'
    )
    FlexComponents = await loadComponent({
      path: `${process.env.FLEX_DESIGN_SYS_REACT_TS_NAME}/Styled`,
      remoteUrl: `${process.env.FLEX_DESIGN_SYS_REACT_TS_HOST}/web`,
      scope: 'default',
      nonce: await fetchFlexCSPNonce(`${process.env.FLEX_GATEWAY_HOST}`),
      // nonce: `${process.env.FLEX_CSP_NONCE}`,
      apiUrl: `${process.env.FLEX_GATEWAY_HOST}`
    })
    // log.info('!isServer FlexComponents : ', FlexComponents)
  }
}

export { FlexComponents }
