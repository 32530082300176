import Link from 'next/link'
import React from 'react'
// Helpers
import { isServer } from 'flex-gateway-ssr/utils'

import {
  FlexGlobalThis
} from 'flexiness'

declare let globalThis: FlexGlobalThis

interface FlexLinkProps {
  onMouseEnter?: React.MouseEventHandler<Element> | undefined
  onClick?: React.MouseEventHandler
  href: string
  target?: string
  rel?: string
  ref?: React.RefObject<Element>
  children: React.ReactNode
}

let flexRouter = globalThis?.Flexiness?.domainApp?.router
const loadFlex = async () => {
  import('@flexiness/domain-lib-mobx-react-router').then((factory) => {
    const { getRouter } = factory
    flexRouter = getRouter()
  })
}

const handleClick = (page: string) => {
  if (!flexRouter) return null
  flexRouter.push(page)
}

const FlexLink: React.FC<FlexLinkProps> = ({ href, target, rel, children }) => {
  if (!isServer) loadFlex()
  return (
    <Link legacyBehavior href={href} passHref>
      <a onClick={() => handleClick(href)} target={target} rel={rel}>{children}</a>
    </Link>
  )
}

export default FlexLink
